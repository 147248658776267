import * as React from "react"
import { wrap, input, quantityLabel } from "./numeric-input.module.css"

export function NumericInput({
  onIncrement,
  onDecrement,
  className,
  disabled,
  labelVis=false,
  ...props
}) {
  return (
    <div className={wrap}> 
    {labelVis && <span className={quantityLabel}>Quantity </span>}
      <button
        disabled={disabled}
        className="hoverableBtn"
        aria-label="Decrement"
        onClick={onDecrement}
      >
        <span>-</span>
      </button>
      
      <input
        disabled
        type="numeric"
        className={[input, className].join(" ")}
        {...props}
      />
    
      <button
        disabled={disabled}
        className="hoverableBtn"
        aria-label="Increment"
        onClick={onIncrement}
      >
        <span>+</span>
      </button>
    </div>
  )
}
