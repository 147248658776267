import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StoreContext } from "../context/store-context"
import { formatPrice } from "../utils/format-price"

import { giftWrapBtn, unavailable } from "./gift-wrap-button.module.css"

const GiftWrapBtn = () => {
  const { addVariantToCart, checkout, loading } = React.useContext(StoreContext)
  const [alreadyInCart, setAlreadyInCart] = React.useState(true)

  const {shopifyProduct: giftWrap} = useStaticQuery(graphql`
    query giftWrapQuery {
      shopifyProduct(title: {eq: "Gift wrap"}) {
        storefrontId
        priceRangeV2 {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }  
        variants {
          storefrontId
          price
        }
      }
    }
  `)

  const price = formatPrice(
    giftWrap.priceRangeV2.minVariantPrice.currencyCode,
    giftWrap.variants[0].price
  )

  React.useEffect(() => {
    const matchGiftWrap = lineItem => lineItem.variant.id === giftWrap.variants[0].storefrontId
    if (checkout.lineItems.length===0) {
      setAlreadyInCart(false)
    } else if (checkout.lineItems.some(matchGiftWrap)) {
      setAlreadyInCart(true)
    } else {
      setAlreadyInCart(false)
    }
  }, [checkout.lineItems])

  const handleClick = () => {
    addVariantToCart(giftWrap.variants[0].storefrontId, 1, true)
    setAlreadyInCart(true)
  }

  return (
    <button 
      className={alreadyInCart ? unavailable : giftWrapBtn} 
      onClick={handleClick}
      disabled={alreadyInCart || loading}
    >+ {price}</button>
  )
}
export default GiftWrapBtn