import * as React from "react"

function CartIcon(props) {
  return (
    <svg
      width={48}
      height={45}
      viewBox="0 0 48 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"Cart"}</title>
      <path d="M46.2775 9.63157C45.3942 8.50291 44.0202 7.8159 42.5971 7.8159H12.7121L11.7798 4.18456C11.24 2.07446 9.37523 0.602295 7.21605 0.602295H2.21069C1.37646 0.602295 0.689453 1.2893 0.689453 2.12353C0.689453 2.95776 1.37646 3.64477 2.21069 3.64477H7.21605C7.95213 3.64477 8.59007 4.13549 8.78636 4.87157L14.7732 28.7206C15.313 30.8307 17.1777 32.3029 19.3369 32.3029H38.9167C41.0758 32.3029 42.9897 30.8307 43.4804 28.7206L47.1608 13.6064C47.5043 12.2324 47.2099 10.7602 46.2775 9.63157ZM44.1674 12.9194L40.487 28.0336C40.2907 28.7697 39.6527 29.2604 38.9167 29.2604H19.3369C18.6008 29.2604 17.9629 28.7697 17.7666 28.0336L13.4973 10.9074H42.5971C43.0878 10.9074 43.5785 11.1528 43.873 11.5454C44.1674 11.938 44.3146 12.4287 44.1674 12.9194Z" fill="#018136"/>
      <path d="M20.3672 34.2659C17.5701 34.2659 15.2637 36.5723 15.2637 39.3694C15.2637 42.1665 17.5701 44.4729 20.3672 44.4729C23.1643 44.4729 25.4707 42.1665 25.4707 39.3694C25.4707 36.5723 23.1643 34.2659 20.3672 34.2659ZM20.3672 41.3813C19.2385 41.3813 18.3552 40.498 18.3552 39.3694C18.3552 38.2407 19.2385 37.3574 20.3672 37.3574C21.4958 37.3574 22.3791 38.2407 22.3791 39.3694C22.3791 40.449 21.4468 41.3813 20.3672 41.3813Z" fill="#018136"/>
      <path d="M37.248 34.2659C34.4509 34.2659 32.1445 36.5723 32.1445 39.3694C32.1445 42.1665 34.4509 44.4729 37.248 44.4729C40.0451 44.4729 42.3515 42.1665 42.3515 39.3694C42.3025 36.5723 40.0451 34.2659 37.248 34.2659ZM37.248 41.3813C36.1194 41.3813 35.2361 40.498 35.2361 39.3694C35.2361 38.2407 36.1194 37.3574 37.248 37.3574C38.3767 37.3574 39.26 38.2407 39.26 39.3694C39.26 40.449 38.3276 41.3813 37.248 41.3813Z" fill="#018136"/>
    </svg>
  )
}

export default CartIcon
