import * as React from "react"
import { Link } from "gatsby"
import { StoreContext } from "./../context/store-context"
import { Navigation } from "./navigation"
import { CartButton } from "./cart-button"
import SearchIcon from "../icons/search"
import { Toast } from "./toast"
import {
  header,
  logo as logoCss,
  searchBtn,
  endpiece,
  navBtn,
  mobileMenuBtn,
  desktopLogo,
  mobileLogo,
  mobileNav,
} from "./header.module.css"

export function Header({ cartVis, setCartVis, mobileNavVis, setMobileNavVis }) {
  const { checkout, loading, didJustAddToCart } = React.useContext(StoreContext)

  const items = checkout ? checkout.lineItems : []

  const quantity = items.reduce((total, item) => {
    return total + item.quantity
  }, 0)

  return (
    <>
      <header className={header}>
        <Link to="/" className={logoCss}>
          <img className={desktopLogo} src='/SPB_Logo-01.svg' alt="Sweet Pickle Books" />
          <img className={mobileLogo} src='/SPB_Logo-02.svg' alt="Sweet Pickle Books" />
        </Link>
        <Link to="/about" className={navBtn}>
          <div>About</div>
        </Link>
        <Link to="/shop" className={navBtn}>
          <div>Shop</div>
        </Link>
        <Link to="/requests" className={navBtn}>
          <div>Request a Book</div>
        </Link>
        <Link to="/blog" className={navBtn}>
          <div>Blog</div>
        </Link>
       <div className={endpiece}>
          <Link to="/search" className={navBtn} id={searchBtn}>
            <div><SearchIcon /></div>
          </Link>

          <CartButton quantity={quantity} cartVis={cartVis} setCartVis={setCartVis} />
          <button className={mobileMenuBtn} onClick={() => setMobileNavVis(!mobileNavVis)}>
            <img src="/hamburger.svg" alt="Mobile Menu" />
          </button>
        </div>

      </header>
      <Toast show={loading || didJustAddToCart}>
        {!didJustAddToCart ? (
          "Updating…"
        ) : (
          "Added to cart!"
        )}
      </Toast>
      {/* {mobileNavVis && */}
      <nav className={mobileNav} style={mobileNavVis ? {top: "3rem",bottom: "0rem"} : {top: "-100%",bottom: "100%"}}>
        <Link to="/about">About</Link>
        <Link to="/shop">Shop</Link>
        <Link to="/requests">Request a Book</Link>
        <Link to="/blog">Blog</Link>
      </nav>
      {/* } */}
    </>
  )
}

// import * as React from "react"
// import { Link } from "gatsby"
// import { StoreContext } from "../context/store-context"
// import Logo from "../icons/logo"
// import { Navigation } from "./navigation"
// import { CartButton } from "./cart-button"
// import SearchIcon from "../icons/search"
// import { Toast } from "./toast"
// import {
//   header,
//   container,
//   logo as logoCss,
//   searchBtn,
//   endpiece,
//   navBtn,
//   mobileMenuBtn,
//   desktopLogo,
//   mobileLogo,
//   mobileNav,
// } from "./header.module.css"

// export function Header({ setCartVis }) {
//   const { checkout, loading, didJustAddToCart } = React.useContext(StoreContext)
//   const [mobileNavVis, setMobileNavVis] = React.useState(false)

//   const items = checkout ? checkout.lineItems : []

//   const quantity = items.reduce((total, item) => {
//     return total + item.quantity
//   }, 0)

//   return (
//     <div className={container}>
//       <header className={header}>
//         <Link to="/" className={logoCss}>
//           <img className={desktopLogo} src='https://cdn.shopify.com/s/files/1/0583/3202/0894/files/Group_127logo.png?v=1627426236' alt="Sweet Pickle Books" />
//           <img className={mobileLogo} src='/mobile-logo.png' alt="Sweet Pickle Books" />
//         </Link>
//         <Link to="/about" className={navBtn}>
//           <div>About</div>
//         </Link>
//         <Link to="/shop" className={navBtn}>
//           <div>Shop</div>
//         </Link>
//         <Link to="/requests" className={navBtn}>
//           <div>Request a Book</div>
//         </Link>
//         <Link to="/blog" className={navBtn}>
//           <div>Blog</div>
//         </Link>
//        <div className={endpiece}>
//           <Link to="/search" className={navBtn} id={searchBtn}>
//             <div><SearchIcon /></div>
//           </Link>

//           <CartButton quantity={quantity} setCartVis={setCartVis} />
//           <button className={mobileMenuBtn} onClick={() => setMobileNavVis(!mobileNavVis)}>
//             <img src="/hamburger.svg" alt="Mobile Menu" />
//           </button>
//         </div>

//       </header>
//       <Toast show={loading || didJustAddToCart}>
//         {!didJustAddToCart ? (
//           "Updating…"
//         ) : (
//           <>
//             Added to cart{" "}
//             <svg
//               width="14"
//               height="14"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 d="M5.019 10.492l-2.322-3.17A.796.796 0 013.91 6.304L6.628 9.14a1.056 1.056 0 11-1.61 1.351z"
//                 fill="#fff"
//               />
//               <path
//                 d="M5.209 10.693a1.11 1.11 0 01-.105-1.6l5.394-5.88a.757.757 0 011.159.973l-4.855 6.332a1.11 1.11 0 01-1.593.175z"
//                 fill="#fff"
//               />
//               <path
//                 d="M5.331 7.806c.272.326.471.543.815.163.345-.38-.108.96-.108.96l-1.123-.363.416-.76z"
//                 fill="#fff"
//               />
//             </svg>
//           </>
//         )}
//       </Toast>
//       {mobileNavVis &&
//       <nav className={mobileNav}>
//         <a href="/about">About</a>
//         <a href="/shop">Shop</a>
//         <a href="/requests">Request a Book</a>
//         <a href="/blog">Blog</a>
//       </nav>
//       }
//     </div>
//   )
// }
