// extracted by mini-css-extract-plugin
export var browseButton = "sliding-cart-module--browseButton--HMA7O";
export var cartContainer = "sliding-cart-module--cartContainer--z1oWM";
export var cartContents = "sliding-cart-module--cartContents--rzi2q";
export var cartFooter = "sliding-cart-module--cartFooter--ueZbQ";
export var cartHeader = "sliding-cart-module--cartHeader--CF6cn";
export var checkoutButton = "sliding-cart-module--checkoutButton--AWw4X";
export var emptyStateContainer = "sliding-cart-module--emptyStateContainer--dJ0z0";
export var emptyStateHeading = "sliding-cart-module--emptyStateHeading--4LG8d";
export var emptyStatePrompt = "sliding-cart-module--emptyStatePrompt--zowvf";
export var headerBtn = "sliding-cart-module--headerBtn--jI71C";
export var subtotal = "sliding-cart-module--subtotal--Ia1WZ";
export var title = "sliding-cart-module--title--fPqVo";
export var upsell = "sliding-cart-module--upsell--G4cT0";