import * as React from "react"
import { useForm } from "react-hook-form";
import addToMailchimp from 'gatsby-plugin-mailchimp'

import {
  footerStyle,
  submitConfirm,
  smallSloganContainer,
  smallSloganText,
  smallSloganAddress,
  addressArrow,
  emailFormContainer,
  emailFormInput,
  emailFormSubmit,
  devSignature,
} from "./footer.module.css"

export function Footer() {
  const { register, handleSubmit } = useForm();
  const [submitted, setSubmitted] = React.useState(false)

  const onSubmit = async (data) => {
    setSubmitted(true)
    const result = await addToMailchimp(data.email)
  }
  return (
    <footer className={footerStyle}>
      <div className={smallSloganContainer}>
        <p className={smallSloganText}>The <span>pickle bookstore</span><br />in New York City's<br /><span>lower east side</span><img className="bookIllus" src="https://cdn.shopify.com/s/files/1/0583/3202/0894/files/book-illus.svg?v=1627331579" alt="small scribbly book" /></p>
        <p className={smallSloganAddress}>47 orchard street new york, ny 10002<img className={addressArrow} src="/addressArrow.svg" alt="decorative arrow" /></p>
      </div>
      
      <div className={emailFormContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="email">Sign up for our <span>newsletter:</span></label>
          {submitted ?
          <p className={submitConfirm}>Thank you!</p>
          :
          <>
            <input className={emailFormInput} type="email" name="email" id="email" placeholder="Your Email..." {...register("email", { required: true })}/>
            <input className={emailFormSubmit} type="submit" value="Submit" />
          </>  
        }
        </form>
      </div>
      <div className={devSignature}>
        <a href="https://www.instagram.com/sweetpicklebooks/?hl=en" target="_blank" rel="noopener noreferrer">
          Instagram
        </a>
        <p>Site by <a href="https://virtuallyreal.nyc/" target="_blank" rel="noopener noreferrer">Virtually Real</a></p>
      </div>
    </footer>
  )
}