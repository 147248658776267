import * as React from "react"
import debounce from "lodash.debounce"
import { StoreContext } from "../context/store-context"
import { formatPrice } from "../utils/format-price"
import { GatsbyImage } from "gatsby-plugin-image"
import { getShopifyImage } from "gatsby-source-shopify"
import { NumericInput } from "./numeric-input"
import {
  title,
  remove,
  lineItemWrap,
  imageContainer,
  itemTotalPrice,
  quantityControl,
} from "./line-item.module.css"
import { Link } from "gatsby"

export function LineItem({ item }) {
  const {
    removeLineItem,
    checkout,
    updateLineItem,
    loading,
  } = React.useContext(StoreContext)
  const [quantity, setQuantity] = React.useState(item.quantity)
  React.useEffect(() => {
    setQuantity(item.quantity)
  },[item.quantity])

  const variantImage = {
    ...item.variant.image,
    originalSrc: item.variant.image.src,
  }

  const subtotal = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount) * quantity
  )

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id)
  }

  const uli = debounce(
    (value) => updateLineItem(checkout.id, item.id, value),
    300
  )
  // eslint-disable-next-line
  const debouncedUli = React.useCallback((value) => uli(value), [])

  const handleQuantityChange = (value) => {
    if (value !== "" && Number(value) < 1) {
      return
    }
    setQuantity(value)
    if (Number(value) >= 1) {
      debouncedUli(value)
    }
  }

  function doIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1)
  }

  function doDecrement() {
    handleQuantityChange(Number(quantity || 0) - 1)
  }

  const image = React.useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        // layout: "constrained",
        // crop: "contain",
        width: 200,
        height: 300,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variantImage.src]
  )

  return (
    <div className={lineItemWrap}>

      <Link to={`/products/${item.variant.product.handle}`} className={imageContainer}>
        {image && (
          <GatsbyImage
            key={variantImage.src}
            image={image}
            alt={variantImage.altText ?? item.variant.title}
            style={{maxHeight: "100%", maxWidth: "100%",}}
            imgStyle={{ objectFit: "contain",}}      
          />
        )}
      </Link>
{/* <Link to={`/products/${item.variant.product.handle}`}> */}
      <h2 className={title}>{item.title} {item.variant.title === "Default Title" ? "" : `(${item.variant.title})`}</h2>
{/* </Link> */}
      <p className={itemTotalPrice}>{subtotal}</p>

      <div className={quantityControl}>
        {item.customAttributes.find(attr=>attr.key==="isUnique").value==="not-unique"
          &&
          <NumericInput
            disabled={loading}
            value={quantity}
            aria-label="Quantity"
            onIncrement={doIncrement}
            onDecrement={doDecrement}
            onChange={(e) => handleQuantityChange(e.currentTarget.value)}
          />
        }
      </div>

        <div className={remove}>
          <button className="hoverableBtn" onClick={handleRemove}>Remove</button>
        </div>

    </div>
  )
}
