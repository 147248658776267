import * as React from "react"
import { Link } from "gatsby"
import { formatPrice } from "../utils/format-price"

import { 
  cartContainer, 
  cartHeader,
  cartContents, 
  title, 
  headerBtn, 
  emptyStateContainer, 
  emptyStateHeading, 
  emptyStatePrompt,
  cartFooter,
  upsell,
  subtotal,
  checkoutButton,
  browseButton
} from "./sliding-cart.module.css"
import { StoreContext } from "../context/store-context"
import { LineItem } from "./line-item"
import GiftWrapBtn from "./gift-wrap-button"
import UpsellPickleBtn from "./upsell-pickle-btn"

const SlidingCart = ({ cartVis, setCartVis }) => {
  const { checkout, loading } = React.useContext(StoreContext)
  const emptyCart = checkout.lineItems.length === 0

  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }

  const reducer = (previousValue, currentValue) => previousValue + currentValue
  const totalItems = checkout.lineItems.length > 0 ? checkout.lineItems.map(item=>item.quantity).reduce(reducer) : 0

  return (
    <aside className={cartContainer} style={cartVis ? {right: "0%"} : {right: "-100%"}} >
      <header className={cartHeader}>
        <h1 className={title}>Cart</h1>
        <button className={headerBtn} onClick={() =>setCartVis(false)}>(Close)</button>
      </header>
      {emptyCart ? (
          <div className={emptyStateContainer}>
            <h1 className={emptyStateHeading}>Your cart is<br /><span>empty</span></h1>
            <p className={emptyStatePrompt}>
              How about some <Link to="/shop/pickles">pickles?</Link>
            </p>
          </div>
        ) : (
          <div className={cartContents}>
            {checkout.lineItems.map((item) => (
                  <LineItem item={item} key={item.id} />
            ))}
          </div>       
          )}
      <footer className={cartFooter}>
        <div className={upsell}>
          <p><span>Total:</span><span>{totalItems} Item{totalItems !== 1 && "s"}</span></p>
          <p><span>Gift Wrap?</span><GiftWrapBtn /></p>
          <p><span>Add a jar of Dill Pickles?</span><UpsellPickleBtn /></p>
        </div>
          
        <p className={subtotal}>{
          checkout.subtotalPriceV2 &&
          formatPrice(
            checkout.subtotalPriceV2.currencyCode,
            checkout.subtotalPriceV2.amount)
          }
        </p>
        {emptyCart ?
        <Link to="/shop" className={browseButton}>
          <button onClick={() => setCartVis(false)}>Browse</button>
        </Link>
        :
        <button
          onClick={handleCheckout}
          disabled={loading}
          className={checkoutButton}
        >
          Checkout
        </button>
        }
        
      </footer>
    </aside>
  )
}
export default SlidingCart