import * as React from "react"
import CartIcon from "../icons/cart"
import { cartButton, badge } from "./cart-button.module.css"

export function CartButton({ quantity, cartVis, setCartVis }) {
  return (
    <div className={cartButton} onClick={() => setCartVis(!cartVis)}>
      <CartIcon />
      {quantity > 0 && <div className={badge}><span>{quantity}</span></div>}
    </div>
  )
}
