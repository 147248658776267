// extracted by mini-css-extract-plugin
export var addressArrow = "footer-module--addressArrow--vjafY";
export var devSignature = "footer-module--devSignature---0Y3p";
export var emailFormContainer = "footer-module--emailFormContainer---yXm3";
export var emailFormInput = "footer-module--emailFormInput--HsJHA";
export var emailFormSubmit = "footer-module--emailFormSubmit--2YgNd";
export var footerStyle = "footer-module--footerStyle--pYd6o";
export var smallSloganAddress = "footer-module--smallSloganAddress--qE6ts";
export var smallSloganContainer = "footer-module--smallSloganContainer--ySfU5";
export var smallSloganText = "footer-module--smallSloganText--3aTZ-";
export var submitConfirm = "footer-module--submitConfirm--NXDr4";