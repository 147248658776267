import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StoreContext } from "../context/store-context"
import { formatPrice } from "../utils/format-price"

import { btnStyle } from "./upsell-pickle-btn.module.css"

const UpsellPickleBtn = () => {
  const { addVariantToCart, loading } = React.useContext(StoreContext)

  const { shopifyProduct: upsellPickle } = useStaticQuery(graphql`
    query upsellPickleQuery {
      shopifyProduct(title: {eq: "Big Jar of Dill Pickles"}) {
        storefrontId
        priceRangeV2 {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }  
        variants {
          storefrontId
          price
        }
      }
    }
  `)

  const price = upsellPickle ? formatPrice(upsellPickle.priceRangeV2.minVariantPrice.currencyCode,upsellPickle.variants[0].price) : null

  const handleClick = () => {
    addVariantToCart(upsellPickle.variants[0].storefrontId, 1)
  }

  return (<>
  {price &&
    <button 
      className={btnStyle} 
      onClick={handleClick}
      disabled={loading}
    >+ {price}</button>
  }
  </>)
}
export default UpsellPickleBtn