import * as React from "react"
import { Header } from "./header"
import { Footer } from "./footer"

import { baseGrid } from "./layout.module.css"
import SlidingCart from "./sliding-cart"

const Layout = ({ children, containerStyle }) => {
  const [cartVis, setCartVis] = React.useState(false)
  const [mobileNavVis, setMobileNavVis] = React.useState(false)

  return (
    <>        
      <div className={baseGrid} style={mobileNavVis ? {position:"fixed" } : {}}>    
        <Header 
          cartVis={cartVis} 
          setCartVis={setCartVis}
          mobileNavVis={mobileNavVis}
          setMobileNavVis={setMobileNavVis} 
        />
        <div className={containerStyle}>
          {children}
          <Footer />
        </div>          
      </div>
      {/* {cartVis && */}
        <SlidingCart cartVis={cartVis} setCartVis={setCartVis} />
      {/* } */}
    </> 
  )
}
export default Layout
// import * as React from "react"
// import { useState } from "react"
// import { SkipNavContent, SkipNavLink } from "./skip-nav"
// import { Header } from "./header"
// import CartDrawer from "./cart-drawer"
// import { Footer } from "./footer"
// import { Seo } from "./seo"

// export function Layout({ children }) {
//   const [cartVis, setCartVis] = useState(false)
//   return (
//     <div>
//       <Seo />
//       <SkipNavLink />
//       <Header 
//         setCartVis={setCartVis}
//       />
//       <CartDrawer 
//         cartVis={cartVis}
//         setCartVis={setCartVis}
//       />
//       <SkipNavContent>{children}</SkipNavContent>
//       <Footer />
//     </div>
//   )
// }
